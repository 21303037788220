import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/account/services/auth.service';
import { selectLoggedInUser } from 'src/app/store/account/account.selectors';
import { getProfileDetails } from 'src/app/store/profile/profile.actions';
import { selectProfileVisibility } from 'src/app/store/profile/profile.selector';
import { ConfirmationPopup } from '../confirmation-popup/confirmation-popup.component';
@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
})
export class SettingsComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  accountId: string = '';
  successMessage: string = '';
  errorMessage: string = '';
  allowProfileVisibilty: boolean = false;
  registerType: string = '';
  constructor(
    private store: Store,
    public dialog: MatDialog,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.subscriptions[0] = this.store
      .select(selectLoggedInUser)
      .subscribe((data) => {
        this.registerType = data.registerType;
      });

    this.subscriptions[1] = this.store
      .select(selectProfileVisibility)
      .subscribe((data) => {
        this.allowProfileVisibilty = data;
      });
  }

  onHideAccountChange(value: any) {
    this.allowProfileVisibilty = value;
    if (value) {
      const dialogRef = this.dialog.open(ConfirmationPopup, {
        data: {
          title: 'Hide Account',
          content: 'Body Content',
          positiveBtnLabel: 'Confirm',
          negativeBtnLabel: 'Cancel',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.authService
            .updateProfileVisibility({
              accountId: this.accountId,
              allowProfileVisibilty: value,
            })
            .subscribe((data) => {
              if (data.status === 'SUCCESS' && data.code === 'CineRush_00001') {
                this.successMessage =
                  'Profile Visibility Updated Successfully.';
              } else {
                this.errorMessage =
                  'Something went wrong. Please try again later.';
              }
              this.store.dispatch(getProfileDetails());
            });
        } else {
          this.allowProfileVisibilty = false;
        }
      });
    } else {
      const dialogRef = this.dialog.open(ConfirmationPopup, {
        data: {
          title: 'Make your account visible',
          content: 'Body Content',
          positiveBtnLabel: 'Confirm',
          negativeBtnLabel: 'Cancel',
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.authService
            .updateProfileVisibility({
              accountId: this.accountId,
              allowProfileVisibilty: this.allowProfileVisibilty,
            })
            .subscribe((data) => {
              if (data.status === 'SUCCESS' && data.code === 'CineRush_00001') {
                this.successMessage =
                  'Profile Visibility Updated Successfully.';
              } else {
                this.errorMessage =
                  'Something went wrong. Please try again later.';
              }
              this.store.dispatch(getProfileDetails());
            });
        } else {
          this.allowProfileVisibilty = true;
        }
      });
    }
  }

  onDeleteAccountChange() {
    const dialogRef = this.dialog.open(ConfirmationPopup, {
      data: {
        title: 'Delete Account Permanently',
        content:
          'Account will be deleted Permanently in 14 days. Do you want to proceed ?',
        positiveBtnLabel: 'Yes',
        negativeBtnLabel: 'No',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log(`Dialog result: ${result}`);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }
}
