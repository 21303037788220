import { ProfileInterface } from '../../shared/interfaces/profile.interface';

export const initialState: ProfileState = {
  profile: {
    id: '',
    demographic: {
      firstName: '',
      lastName: '',
      city: '',
      emailId: '',
      zipCode: 0,
      gender: '',
      phoneNumber: '',
      dob: '',
      bio: '',
      registerType: '',
      accountId: '',
      country: '',
      ethnicity: '',
      state: '',
      nationality:''
    },
    skillOptions: [],
    lookingFor: [],
    mediaLinks: [],
    videoLinks: [],
    workExperience: [],
    educationDetails: [],
    physicalAttributes: {
      weight: '',
      height: '',
      bodyType: '',
      eyecolor: '',
      hairType: '',
      skintone: '',
      hairColor: '',
    },
    profileImage: '',
    portfolio0: '',
    portfolio1: '',
    portfolio2: '',
    portfolio3: '',
    languages: [],
    allowProfileVisibilty: false,
    isRecruiterBookMarkedProfile: false,
    isRecruiterLikedProfile: false,
    profileLikedCount: 0,
  },
  registerType: '',
  getProfileDetailsError: '',
  allProfiles: [],
  redirectToUserProfile: false,
};

export interface ProfileState {
  profile: ProfileInterface;
  registerType: string;
  getProfileDetailsError: string;
  allProfiles: ProfileInterface[];
  redirectToUserProfile: boolean;
}
