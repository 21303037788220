import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap } from 'rxjs';
import { JobsService } from 'src/app/jobs/services/jobs.service';
import { openSnackBar, toggleSpinner } from '../core/core.actions';
import {
  acceptApplicant,
  acceptApplicantFailure,
  acceptApplicantSuccess,
  applyJob,
  applyJobFailure,
  applyJobSuccess,
  createJob,
  createJobFailure,
  createJobSuccess,
  deleteJob,
  getJobDetails,
  getJobDetailsError,
  getJobDetailsSuccess,
  getJobs,
  getJobsError,
  getJobsSuccess,
  rejectApplicant,
  rejectApplicantFailure,
  rejectApplicantSuccess,
  setFavorite,
  setFavoriteUpdated,
  setViewed,
} from './jobs.actions';

@Injectable()
export class JobsEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private jobsService: JobsService
  ) {}

  // withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
  //     mergeMap(([action, loggedInDetails]) => {

  $createJob = createEffect(() =>
    this.actions$.pipe(
      ofType(createJob),
      mergeMap((action) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Submiting...' } })
        );
        return this.jobsService.createJob(action.job).pipe(
          map((response) => {
            this.store.dispatch(
              toggleSpinner({ spinner: { show: false, message: '' } })
            );
            if (
              response.response.code === 'CineRush_00001' &&
              response.response.status === 'SUCCESS'
            ) {
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Show Case created successfully',
                    show: true,
                  },
                })
              );
              return createJobSuccess({
                createJobSuccess: true,
                jobId: response.jobId,
              });
            } else {
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Error in creating post.',
                    show: true,
                  },
                })
              );
              return createJobFailure({ createJobError: true });
            }
          }),
          catchError((error) => {
            this.store.dispatch(
              toggleSpinner({ spinner: { show: false, message: '' } })
            );
            this.store.dispatch(
              openSnackBar({
                snackBar: {
                  message: 'Error in creating post.',
                  show: true,
                },
              })
            );
            return [createJobFailure({ createJobError: true })];
          })
        );
      })
    )
  );

  $getJobDetails = createEffect(() =>
    this.actions$.pipe(
      ofType(getJobDetails),
      mergeMap((action) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Fetching...' } })
        );
        return this.jobsService
          .getJobDetails({
            jobId: action.jobId,
            accountId: action.accountId,
            registerType: action.registerType,
          })
          .pipe(
            map((job) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              if (job.status === 'SUCCESS' && job.code === 'CineRush_00001') {
                return getJobDetailsSuccess({ job: job.jobResponse });
              } else {
                return getJobDetailsError({ error: job.message });
              }
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              return [getJobDetailsError({ error })];
            })
          );
      })
    )
  );

  $getJobs = createEffect(() =>
    this.actions$.pipe(
      ofType(getJobs),
      mergeMap((action) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Fetching...' } })
        );
        return this.jobsService
          .getJobs({
            filter: action.filter,
          })
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              if (
                response.status === 'SUCCESS' &&
                response.code === 'CineRush_00001'
              ) {
                if (action.applied) {
                  response.jobDetails = response.jobDetails.filter(
                    (element: any) => element.isApplied
                  );
                }
                if (action.bookmarked) {
                  response.jobDetails = response.jobDetails.filter(
                    (element: any) => element.isFavourite
                  );
                }

                return getJobsSuccess({ jobs: response.jobDetails });
              } else {
                return getJobsError({ error: response.message });
              }
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              return [getJobsError({ error })];
            })
          );
      })
    )
  );

  $applyJob = createEffect(() =>
    this.actions$.pipe(
      ofType(applyJob),
      mergeMap((action) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Applying...' } })
        );
        return this.jobsService
          .applyJob({
            jobId: action.jobId,
            accountId: action.accountId,
            registerType: action.registerType,
          })
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Applied for Opportunity.',
                    show: true,
                  },
                })
              );
              this.store.dispatch(
                getJobs({
                  filter: {},
                })
              );
              this.store.dispatch(
                getJobDetails({
                  jobId: action.jobId,
                  accountId: action.accountId,
                  registerType: action.registerType,
                })
              );
              return applyJobSuccess({ job: response });
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Failed to apply Opportunity.',
                    show: true,
                  },
                })
              );
              return [applyJobFailure({ error })];
            })
          );
      })
    )
  );

  $setFavorite = createEffect(() =>
    this.actions$.pipe(
      ofType(setFavorite),
      mergeMap((action) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Saving...' } })
        );
        return this.jobsService
          .setFavorite({
            jobId: action.jobId,
            accountId: action.accountId,
          })
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              return setFavoriteUpdated({ value: true });
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              return [setFavoriteUpdated({ value: false })];
            })
          );
      })
    )
  );

  $setViewed = createEffect(() =>
    this.actions$.pipe(
      ofType(setViewed),
      mergeMap((action) => {
        return this.jobsService
          .setViewed({
            jobId: action.jobId,
            accountId: action.accountId,
          })
          .pipe(
            map((response) => {
              return setFavoriteUpdated({ value: true });
            }),
            catchError((error) => {
              return [setFavoriteUpdated({ value: false })];
            })
          );
      })
    )
  );

  $acceptApplicant = createEffect(() =>
    this.actions$.pipe(
      ofType(acceptApplicant),
      mergeMap((action) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Accepting...' } })
        );
        return this.jobsService
          .accentApplicant({
            jobId: action.jobId,
            accountId: action.accountId,
          })
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                getJobs({
                  filter: {},
                })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Accepted applicant',
                    show: true,
                  },
                })
              );
              return acceptApplicantSuccess({ job: response });
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'failed to accept applicant',
                    show: true,
                  },
                })
              );
              return [acceptApplicantFailure({ error })];
            })
          );
      })
    )
  );

  $rejectApplicant = createEffect(() =>
    this.actions$.pipe(
      ofType(rejectApplicant),
      mergeMap((action) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Rejecting...' } })
        );
        return this.jobsService
          .rejectApplicant({
            jobId: action.jobId,
            accountId: action.accountId,
          })
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                getJobs({
                  filter: {},
                })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Rejected applicant',
                    show: true,
                  },
                })
              );
              return rejectApplicantSuccess({ job: response });
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Failed to reject applicant',
                    show: true,
                  },
                })
              );
              return [rejectApplicantFailure({ error })];
            })
          );
      })
    )
  );

  $deleteJob = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteJob),
      mergeMap((action) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Deleting...' } })
        );
        return this.jobsService
          .deleteJob({
            jobId: action.jobId,
            accountId: action.accountId,
          })
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                getJobs({
                  filter: {},
                })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Deleted job Successfully',
                    show: true,
                  },
                })
              );
              return getJobs({ filter: {} });
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Failed to delete job',
                    show: true,
                  },
                })
              );
              return [getJobs({ filter: {} })];
            })
          );
      })
    )
  );
}
