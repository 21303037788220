import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, mergeMap } from 'rxjs';
import { constants } from 'src/app/shared/utils/constants';
import { appLogout } from '..';
import { AuthService } from '../../account/services/auth.service';
import { openSnackBar, toggleSpinner } from '../core/core.actions';
import {
  getProfileDetails,
  updateLoginPersonalData,
} from '../profile/profile.actions';
import {
  getRecruiterDetails,
  updateRecruiterDetails,
} from '../recruiter/recruiter.actions';
import {
  changePassword,
  changePasswordError,
  changePasswordSuccess,
  getTokens,
  registerUser,
  registerUserError,
  registerUserSuccess,
  setAuthToken,
  setLoggedInUser,
  setRefreshToken,
  setUserVerified,
  userLogin,
  userLoginError,
} from './account.actions';

@Injectable()
export class AccountEffects {
  constructor(
    private authService: AuthService,
    private actions$: Actions,
    private store: Store,
    private router: Router
  ) {}

  registerUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(registerUser),
      mergeMap((profileData) => {
        this.store.dispatch(
          toggleSpinner({
            spinner: {
              message: 'Signing Up...',
              show: true,
            },
          })
        );
        return this.authService.registerUser(profileData.payload).pipe(
          map((response) => {
            this.store.dispatch(
              toggleSpinner({
                spinner: {
                  message: '',
                  show: false,
                },
              })
            );
            if (
              response.status === 'SUCCESS' &&
              (response.code === 'CineRush_00001' ||
                response.code === 'VERIFICATION_001')
            ) {
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message:
                      'Account created successfully. Please verify before using the application.',
                    show: true,
                  },
                })
              );
              this.store.dispatch(
                setUserVerified({
                  status: false,
                  emailId: response.emailId,
                })
              );
              return registerUserSuccess({
                status: true,
                message:
                  'Account created successfully. Please verify before using the application.',
              });
            } else if (
              response.status === 'SUCCESS' &&
              response.code === 'CineRush_00003'
            ) {
              return registerUserError({
                status: true,
                message: response.succsessMessage,
              });
            } else {
              return registerUserError({
                status: true,
                message: response.errorMessage,
              });
            }
          }),
          catchError((error) => {
            this.store.dispatch(
              toggleSpinner({
                spinner: {
                  message: '',
                  show: false,
                },
              })
            );
            return [registerUserError({ status: true, message: error })];
          })
        );
      })
    );
  });

  userLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userLogin),
      mergeMap((profileData) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { message: 'Logging In', show: true } })
        );
        return this.authService.login(profileData.payload).pipe(
          map((response) => {
            this.store.dispatch(
              toggleSpinner({ spinner: { message: '', show: false } })
            );
            if (
              response.status === 'SUCCESS' &&
              response.code === 'CineRush_00005' &&
              response.registerType === constants.roles.user
            ) {
              this.store.dispatch(
                updateLoginPersonalData({
                  emailId: response.emailId,
                  registerType: response.registerType,
                  firstName: response.firstName,
                  lastName: response.lastName,
                  phoneNumber: response.phoneNumber,
                })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Login success.',
                    show: true,
                  },
                })
              );
              this.store.dispatch(setAuthToken({ token: response.token }));
              this.store.dispatch(
                setRefreshToken({ token: response.refreshToken })
              );
              this.store.dispatch(getProfileDetails());
              this.router.navigate(['/user']);
              return setLoggedInUser({
                emailId: response.emailId,
                profileProgress: 0,
                registerType: response.registerType,
              });
            } else if (
              response.status === 'SUCCESS' &&
              response.code === 'CineRush_00005' &&
              (response.registerType === constants.roles.recruiter ||
                response.registerType === constants.roles.indFilmMaker ||
                response.registerType === constants.roles.businessPartner)
            ) {
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Login success.',
                    show: true,
                  },
                })
              );
              this.store.dispatch(
                updateRecruiterDetails({
                  emailId: response.emailId,
                  registerType: response.registerType,
                  firstName: response.firstName,
                  lastName: response.lastName,
                  phoneNumber: response.phoneNumber,
                })
              );
              this.store.dispatch(setAuthToken({ token: response.token }));
              this.store.dispatch(
                setRefreshToken({ token: response.refreshToken })
              );
              this.store.dispatch(getRecruiterDetails());
              this.router.navigate(['/recruiter']);
              return setLoggedInUser({
                emailId: response.emailId,
                profileProgress: 0,
                registerType: response.registerType,
              });
            } else if (
              response.status === 'SUCCESS' &&
              response.code === 'VERIFICATION_001' &&
              !response.isEmailVerified
            ) {
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message:
                      'Account not verified. Please verify before using the application.',
                    show: true,
                  },
                })
              );
              this.router.navigate(['/account/email-verification']);
              return setUserVerified({
                status: false,
                emailId: profileData.payload.emailId,
              });
            } else {
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Login Failed.',
                    show: true,
                  },
                })
              );
              return userLoginError({
                status: true,
                message: response.succsessMessage,
              });
            }
          }),
          catchError((error) => {
            this.store.dispatch(
              openSnackBar({
                snackBar: {
                  message: 'Login Failed.',
                  show: true,
                },
              })
            );
            this.store.dispatch(
              toggleSpinner({ spinner: { message: '', show: false } })
            );
            return [userLoginError({ status: true, message: error })];
          })
        );
      })
    );
  });

  updatePassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(changePassword),
      mergeMap((payload) => {
        this.store.dispatch(
          toggleSpinner({
            spinner: { message: 'Updating Password...', show: true },
          })
        );
        return this.authService.changePassword(payload.payload).pipe(
          map((response) => {
            this.store.dispatch(
              toggleSpinner({ spinner: { message: '', show: false } })
            );
            if (
              response.status === 'SUCCESS' &&
              response.code === 'CineRush_00001'
            ) {
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Password updated.',
                    show: true,
                  },
                })
              );
              return changePasswordSuccess({
                status: true,
                message: response.succsessMessage,
              });
            } else {
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Password update failed.',
                    show: true,
                  },
                })
              );
              return changePasswordError({
                status: true,
                message: response.errorMessage,
              });
            }
          }),
          catchError((error) => {
            this.store.dispatch(
              toggleSpinner({ spinner: { message: '', show: false } })
            );
            this.store.dispatch(
              openSnackBar({
                snackBar: {
                  message: 'Password update failed.',
                  show: true,
                },
              })
            );
            return [changePasswordError({ status: true, message: error })];
          })
        );
      })
    );
  });

  getTokens$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getTokens),
      mergeMap((payload) => {
        return this.authService.getNewTokens(payload.token).pipe(
          map((response: any) => {
            if (response.token && response.refreshToken) {
              this.store.dispatch(setAuthToken({ token: response.token }));
              return setRefreshToken({ token: response.refreshToken });
            } else {
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Refresh token failed.',
                    show: true,
                  },
                })
              );
              this.router.navigate(['/account/login']);
              return appLogout();
            }
          }),
          catchError((error) => {
            this.store.dispatch(
              openSnackBar({
                snackBar: {
                  message: 'Refresh token failed.',
                  show: true,
                },
              })
            );
            this.router.navigate(['/account/login']);
            return [appLogout()];
          })
        );
      })
    );
  });
}
