import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { appLogout } from 'src/app/store';

@Injectable({
  providedIn: 'root',
})
export class LoginResolveGuard implements Resolve<boolean> {
  constructor(private store: Store) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Promise<boolean> | Observable<boolean> {
    this.store.dispatch(appLogout());
    return true;
  }
}
