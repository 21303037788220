import { createReducer, on } from '@ngrx/store';
import {
  getAllProfilesError,
  getAllProfilesSuccess,
  getAppliedProfilesError,
  getAppliedProfilesSuccess,
  getProfileDetailsError,
  getProfileDetailsSuccess,
  redirectToUserProfilePage,
  updateLoginPersonalData,
  updateProfileState,
} from './profile.actions';
import { ProfileState, initialState } from './profile.state';

const _profileReducer = createReducer(
  initialState,
  on(updateProfileState, (state: ProfileState, { profileDetails }) => {
    return {
      ...state,
      profile: { ...profileDetails },
    };
  }),
  on(
    updateLoginPersonalData,
    (
      state: ProfileState,
      { emailId, registerType, firstName, lastName, phoneNumber }
    ) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          demographic: {
            ...state.profile.demographic,
            emailId: emailId,
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            registerType: registerType,
          },
        },
        registerType: registerType,
      };
    }
  ),
  on(getProfileDetailsSuccess, (state: ProfileState, { payload }) => {
    return {
      ...state,
      profile: { ...payload },
    };
  }),
  on(getProfileDetailsError, (state: ProfileState, { payload }) => {
    return { ...state, getProfileDetailsError: payload };
  }),
  on(getAppliedProfilesSuccess, (state: ProfileState, { payload }) => {
    return {
      ...state,
      appliedProfiles: payload,
    };
  }),
  on(getAppliedProfilesError, (state: ProfileState, { error, message }) => {
    return {
      ...state,
      error: error,
      errorMessage: message,
    };
  }),
  on(getAllProfilesSuccess, (state: ProfileState, { payload }) => {
    return {
      ...state,
      allProfiles: payload,
    };
  }),
  on(getAllProfilesError, (state: ProfileState, { error, message }) => {
    return {
      ...state,
      allProfiles: [],
      error: error,
      errorMessage: message,
    };
  }),
  on(
    redirectToUserProfilePage,
    (state: ProfileState, { redirectToUserProfile }) => {
      return {
        ...state,
        redirectToUserProfile: redirectToUserProfile,
      };
    }
  )
);

export function profileReducer(state: any, action: any) {
  return _profileReducer(state, action);
}
