import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  selectAuthToken,
  selectLoggedInUser,
} from '../account/account.selectors';
import { ProfileState } from './profile.state';

export const featureKey = 'profileState';
export const selectProfileState =
  createFeatureSelector<ProfileState>(featureKey);

export const selectAllProfiles = createSelector(
  selectProfileState,
  (state: ProfileState) => state.allProfiles
);

export const selectFullName = createSelector(
  selectProfileState,
  (state: ProfileState) =>
    state.profile.demographic.firstName + state.profile.demographic.lastName
);

export const selectProfileVisibility = createSelector(
  selectProfileState,
  (state: ProfileState) => state.profile.allowProfileVisibilty
);

export const selectProfileImage = createSelector(
  selectProfileState,
  selectAuthToken,
  selectLoggedInUser,
  (state: ProfileState, token: string, loggedInUserDetails) => {
    return {
      fileName: state?.profile?.profileImage,
      token: token,
      registerType: loggedInUserDetails.registerType,
    };
  }
);

export const selectProfilePhotosLinks = createSelector(
  selectProfileState,
  selectAuthToken,
  selectLoggedInUser,
  (state: ProfileState, token: string, loggedInUserDetails) => {
    return {
      portfolio0: state?.profile?.portfolio0,
      portfolio1: state?.profile?.portfolio1,
      portfolio2: state?.profile?.portfolio2,
      portfolio3: state?.profile?.portfolio3,
      accountId: '',
      token: token,
      registerType: loggedInUserDetails.registerType,
    };
  }
);

export const selectRedirectToUserProfile = createSelector(
  selectProfileState,
  (state: ProfileState) => state.redirectToUserProfile
);
