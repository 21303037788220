import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  selectAuthToken,
  selectLoggedInUser,
} from '../account/account.selectors';
import { selectProfileState } from '../profile/profile.selector';
import { ProfileState } from '../profile/profile.state';
import { RecruiterState } from './recruiter.state';

export const featureKey = 'recruiterState';
export const selectRecruiterState =
  createFeatureSelector<RecruiterState>(featureKey);

export const selectViewReadOnlyProfile = createSelector(
  selectRecruiterState,
  selectProfileState,
  (state: RecruiterState, profile: ProfileState) =>
    profile?.allProfiles?.find(
      (profile) => profile?.id === state.viewReadOnlyProfileDetails?.id
    )
);

export const selectShowRecruiterProfile = createSelector(
  selectRecruiterState,
  (state: RecruiterState) => state.showRecruiterProfile
);

export const selectRecruiterProfileDetails = createSelector(
  selectRecruiterState,
  (state: RecruiterState) => state.recruiterDetails
);

export const selectProfilesFilter = createSelector(
  selectRecruiterState,
  (state: RecruiterState) => state.profileFilter
);

export const selectRecruiterProfilePhoto = createSelector(
  selectRecruiterState,
  selectAuthToken,
  selectLoggedInUser,
  (state: RecruiterState, token, loggedInUserDetails) => {
    return {
      profileImage: state.recruiterDetails.orgLogo,
      token: token,
      registerType: loggedInUserDetails.registerType,
    };
  }
);

export const selectViewReadOnlyProfilePhoto = createSelector(
  selectViewReadOnlyProfile,
  selectAuthToken,
  selectLoggedInUser,
  (profile, token: string, loggedInUserDetails) => {
    return {
      profileImage: profile?.profileImage,
      accountId: profile?.demographic.accountId,
      token: token,
      registerType: loggedInUserDetails.registerType,
    };
  }
);

export const selectViewReadOnlyPortfolioPhotos = createSelector(
  selectViewReadOnlyProfile,
  selectAuthToken,
  selectLoggedInUser,
  (profile, token: string, loggedInUserDetails) => {
    return {
      portfolio0: profile?.portfolio0,
      portfolio1: profile?.portfolio1,
      portfolio2: profile?.portfolio2,
      portfolio3: profile?.portfolio3,
      accountId: profile?.demographic.accountId,
      token: token,
      registerType: loggedInUserDetails.registerType,
    };
  }
);
