import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs';
import { ProfileService } from 'src/app/profile/services/profile.service';
import { MediaUtilityService } from 'src/app/shared/services/media-utility.service';
import { selectLoggedInUser } from '../account/account.selectors';
import { openSnackBar, toggleSpinner } from '../core/core.actions';
import { getRecruiterDetails } from '../recruiter/recruiter.actions';
import {
  deleteProfileImage,
  getAllProfiles,
  getAllProfilesError,
  getAllProfilesSuccess,
  getAppliedProfiles,
  getAppliedProfilesError,
  getAppliedProfilesSuccess,
  getProfileDetails,
  getProfileDetailsError,
  getProfileDetailsSuccess,
  redirectToUserProfilePage,
  setProfileDetailsAPI,
  uploadProfileImage,
} from './profile.actions';

@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private profileService: ProfileService,
    private store: Store,
    private mediaService: MediaUtilityService
  ) {}
  setProfile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setProfileDetailsAPI),
      mergeMap((profileNewData) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Updating...' } })
        );
        let profileData = Object.assign({}, profileNewData.payload);
        delete profileData.id;
        return this.profileService.setProfileDetails(profileData).pipe(
          map((response) => {
            this.store.dispatch(
              toggleSpinner({ spinner: { show: false, message: '' } })
            );
            this.store.dispatch(
              openSnackBar({
                snackBar: {
                  message: 'Updated Profile',
                  show: true,
                },
              })
            );
            return getProfileDetails();
          }),
          catchError((error) => {
            this.store.dispatch(
              toggleSpinner({ spinner: { show: false, message: '' } })
            );
            this.store.dispatch(
              openSnackBar({
                snackBar: {
                  message: 'Failed to update profile',
                  show: true,
                },
              })
            );
            return [getProfileDetails()];
          })
        );
      })
    );
  });

  getProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getProfileDetails),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      mergeMap(([action, loggedInDetails]) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Fetching' } })
        );
        return this.profileService.getProfileDetails().pipe(
          map((response) => {
            this.store.dispatch(
              toggleSpinner({ spinner: { show: false, message: '' } })
            );
            if (
              response.status === 'FAILED' &&
              response.code === 'CineRush_00006'
            ) {
              return redirectToUserProfilePage({ redirectToUserProfile: true });
            } else {
              this.store.dispatch(
                redirectToUserProfilePage({ redirectToUserProfile: false })
              );
              return getProfileDetailsSuccess({
                payload: response.profileDetails,
              });
            }
          }),
          catchError((error) => {
            this.store.dispatch(
              toggleSpinner({ spinner: { show: false, message: '' } })
            );
            return [getProfileDetailsError({ payload: error })];
          })
        );
      })
    )
  );

  getAllProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAllProfiles),
      mergeMap((payload) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Fetching...' } })
        );
        return this.profileService
          .getAllProfiles({
            appliedList: payload.appliedList,
            filter: payload.filter,
            registerType: payload.registerType,
          })
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              if (
                response.status === 'SUCCESS' &&
                response.code === 'CineRush_00001'
              ) {
                return getAllProfilesSuccess({
                  payload: response.profileDetails,
                });
              } else {
                return getAllProfilesError({
                  error: true,
                  message: response.succsessMessage,
                });
              }
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );

              return [
                getAllProfilesError({
                  error: true,
                  message: error,
                }),
              ];
            })
          );
      })
    )
  );

  getAppliedProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAppliedProfiles),
      mergeMap((payload) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Fetching...' } })
        );
        return this.profileService
          .getAllProfiles({
            appliedList: payload.appliedList,
            filter: payload.filter,
            registerType: payload.registerType,
          })
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              if (
                response.status === 'SUCCESS' &&
                response.code === 'CineRush_00001'
              ) {
                return getAppliedProfilesSuccess({
                  payload: response.demographic,
                });
              } else {
                return getAppliedProfilesError({
                  error: true,
                  message: response.succsessMessage,
                });
              }
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              return [
                getAppliedProfilesError({
                  error: true,
                  message: error,
                }),
              ];
            })
          );
      })
    )
  );

  uploadProfileImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(uploadProfileImage),
      mergeMap((payload) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Uploading...' } })
        );
        return this.profileService
          .uploadProfileImage(payload.payload.data)
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              if (
                response.status === 'SUCCESS' &&
                response.code === 'CineRush_00001'
              ) {
                this.store.dispatch(
                  openSnackBar({
                    snackBar: {
                      message: 'Uploaded Successfully.',
                      show: true,
                    },
                  })
                );
              }

              return payload.payload.registerType === 'USER'
                ? getProfileDetails()
                : getRecruiterDetails();
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Upload failed.',
                    show: true,
                  },
                })
              );
              return [
                payload.payload.registerType === 'USER'
                  ? getProfileDetails()
                  : getRecruiterDetails(),
              ];
            })
          );
      })
    )
  );

  deleteImage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteProfileImage),
      mergeMap((payload) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Deleting...' } })
        );
        return this.mediaService.deleteUploadedImage(payload.payload).pipe(
          map((response) => {
            this.store.dispatch(
              toggleSpinner({ spinner: { show: false, message: '' } })
            );
            if (
              response.status === 'SUCCESS' &&
              response.code === 'CineRush_00001'
            ) {
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Deleted Successfully.',
                    show: true,
                  },
                })
              );
            }
            return payload.registerType === 'USER'
              ? getProfileDetails()
              : getRecruiterDetails();
          }),
          catchError((error) => {
            this.store.dispatch(
              toggleSpinner({ spinner: { show: false, message: '' } })
            );
            this.store.dispatch(
              openSnackBar({
                snackBar: {
                  message: 'Delete failed.',
                  show: true,
                },
              })
            );
            return [
              payload.registerType === 'USER'
                ? getProfileDetails()
                : getRecruiterDetails(),
            ];
          })
        );
      })
    )
  );
}
