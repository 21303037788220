import { IJob } from 'src/app/recruiter/interfaces/job.interface';
import { ProfileInterface } from 'src/app/shared/interfaces/profile.interface';

export const initialState: RecruiterState = {
  jobs: [],
  viewReadOnlyProfileDetails: null,
  showRecruiterProfile: false,
  recruiterDetails: {
    emailId: '',
    registerType: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    accountId: '',
    orgUrl: '',
    orgDescription: '',
    roleInOrg: '',
    orgLogo: '',
    orgName: '',
  },
  profileFilter: {},
};

export interface RecruiterState {
  jobs: IJob[];
  recruiterDetails: {
    emailId: string;
    registerType: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    accountId: string;
    orgUrl: string;
    orgDescription: string;
    roleInOrg: string;
    orgLogo: string;
    orgName: string;
  };
  viewReadOnlyProfileDetails: ProfileInterface | null;
  showRecruiterProfile: boolean;
  profileFilter: any;
}
