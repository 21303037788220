import { createAction, props } from '@ngrx/store';

export const setLoggedInUser = createAction(
  '[Account] logged in user',
  props<{
    emailId: string;
    profileProgress: number;
    registerType: string;
  }>()
);

export const userLogin = createAction(
  '[Account] user login',
  props<{ payload: { emailId: string; password: string } }>()
);

export const userLoginError = createAction(
  '[Account] user login error',
  props<{ status: boolean; message: string }>()
);

export const setUserVerified = createAction(
  '[Account] user login error',
  props<{ status: boolean; emailId: string }>()
);

export const changePassword = createAction(
  '[Account] update password',
  props<{
    payload: {
      oldpswrd: string;
      emailId: string;
      newpswrd: string;
    };
  }>()
);

export const changePasswordSuccess = createAction(
  '[Account] update password success',
  props<{ status: boolean; message: string }>()
);

export const changePasswordError = createAction(
  '[Account] update password error',
  props<{ status: boolean; message: string }>()
);

export const registerUser = createAction(
  '[Account] register user',
  props<{
    payload: {
      firstName: string;
      lastName: string;
      password: string;
      emailId: string;
      phoneNumber: string;
      registerType: string;
    };
  }>()
);

export const registerUserSuccess = createAction(
  '[Account] register user success',
  props<{ status: boolean; message: string }>()
);

export const registerUserError = createAction(
  '[Account] register user error',
  props<{ status: boolean; message: string }>()
);

export const setAuthToken = createAction(
  '[Account] set auth token',
  props<{ token: string }>()
);

export const setRefreshToken = createAction(
  '[Account] set refresh token',
  props<{ token: string }>()
);

export const getTokens = createAction(
  '[Account] get tokens',
  props<{ token: string }>()
);

export const getTokensFailed = createAction(
  '[Account] get tokens failed',
  props<{ status: boolean }>()
);
