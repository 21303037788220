import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs';
import { RecruiterService } from 'src/app/recruiter/services/recruiter.service';
import { selectLoggedInUser } from '../account/account.selectors';
import { openSnackBar, toggleSpinner } from '../core/core.actions';
import { getAllProfiles } from '../profile/profile.actions';
import {
  bookmarkUserProfile,
  getJobsCreated,
  getJobsCreatedError,
  getJobsCreatedSuccess,
  getRecruiterDetails,
  getRecruiterDetailsError,
  getRecruiterDetailsSuccess,
  showRecruiterProfile,
  updateLikeForUserProfile,
  updateRecruiterProfileDetails,
} from './recruiter.actions';

@Injectable()
export class RecruiterEffects {
  constructor(
    private actions$: Actions,
    private recruiterService: RecruiterService,
    private store: Store
  ) {}

  getRecruiter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getRecruiterDetails),
      mergeMap((payload) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Fetching...' } })
        );
        return this.recruiterService.getRecruiterDetails().pipe(
          map((response) => {
            this.store.dispatch(
              toggleSpinner({ spinner: { show: false, message: '' } })
            );
            if (
              response.status === 'SUCCESS' &&
              response.code === 'CineRush_00006'
            ) {
              this.store.dispatch(
                showRecruiterProfile({ showRecruiterProfile: true })
              );
              return getRecruiterDetailsSuccess({ payload: {} });
            } else {
              this.store.dispatch(
                showRecruiterProfile({ showRecruiterProfile: false })
              );
              return getRecruiterDetailsSuccess({
                payload: response.recruiterProfile,
              });
            }
          }),
          catchError((error) => {
            this.store.dispatch(
              toggleSpinner({ spinner: { show: false, message: '' } })
            );
            return [getRecruiterDetailsError({ error: true, message: error })];
          })
        );
      })
    )
  );

  getJobsCreated$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getJobsCreated),
      mergeMap((payload) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Fetching...' } })
        );
        return this.recruiterService
          .getJobsCreated({
            filter: payload.filter,
          })
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );

              return getJobsCreatedSuccess({ payload: response });
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              return [getJobsCreatedError({ error: true, message: error })];
            })
          );
      })
    )
  );

  updateRecruiterProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateRecruiterProfileDetails),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      mergeMap(([action, loggedInDetails]) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Updating...' } })
        );
        return this.recruiterService
          .updateRecruiterProfileDetails(action.payload)
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Profile updated.',
                    show: true,
                  },
                })
              );

              return getAllProfiles({
                appliedList: [],
                filter: {},
                registerType: loggedInDetails.registerType,
              });
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Profile update failed.',
                    show: true,
                  },
                })
              );
              return [
                getAllProfiles({
                  appliedList: [],
                  filter: {},
                  registerType: loggedInDetails.registerType,
                }),
              ];
            })
          );
      })
    )
  );

  updateLikeForUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLikeForUserProfile),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      mergeMap(([action, loggedInDetails]) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Updating...' } })
        );
        return this.recruiterService
          .likeUserProfile({
            accountId: action.accountId,
            interactionFlag: action.interactionFlag,
          })
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Profile updated.',
                    show: true,
                  },
                })
              );

              return getAllProfiles({
                appliedList: [],
                filter: {},
                registerType: loggedInDetails.registerType,
              });
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Profile update failed.',
                    show: true,
                  },
                })
              );
              return [
                getAllProfiles({
                  appliedList: [],
                  filter: {},
                  registerType: loggedInDetails.registerType,
                }),
              ];
            })
          );
      })
    )
  );

  bookmarkUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(bookmarkUserProfile),
      withLatestFrom(this.store.pipe(select(selectLoggedInUser))),
      mergeMap(([action, loggedInDetails]) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Updating...' } })
        );
        return this.recruiterService
          .bookMarkUserProfile({
            accountId: action.accountId,
            interactionFlag: action.interactionFlag,
          })
          .pipe(
            map((response) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Profile updated.',
                    show: true,
                  },
                })
              );

              return getAllProfiles({
                appliedList: [],
                filter: {},
                registerType: loggedInDetails.registerType,
              });
            }),
            catchError((error) => {
              this.store.dispatch(
                toggleSpinner({ spinner: { show: false, message: '' } })
              );
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Profile update failed.',
                    show: true,
                  },
                })
              );
              return [
                getAllProfiles({
                  appliedList: [],
                  filter: {},
                  registerType: loggedInDetails.registerType,
                }),
              ];
            })
          );
      })
    )
  );
}
