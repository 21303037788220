import { createAction, props } from '@ngrx/store';
import { IChat } from 'src/app/shared/interfaces/chat.interface';
import { IMessage } from 'src/app/shared/interfaces/message.interface';

export const getChats = createAction('[Messages] Get Chats');
export const getChatsSuccess = createAction(
  '[Messages] Get Chats Success',
  props<{
    chats: IChat[];
  }>()
);
export const getChatsError = createAction(
  '[Messages] Get Chats Error',
  props<{
    message: string;
  }>()
);

export const getMessages = createAction(
  '[Messages] Get Messages',
  props<{
    chatId: string;
  }>()
);
export const getMessagesSuccess = createAction(
  '[Messages] Get Messages Success',
  props<{
    messages: IMessage[];
  }>()
);
export const getMessagesError = createAction(
  '[Messages] Get Messages Error',
  props<{
    message: string;
  }>()
);

export const setSelectedChat = createAction(
  '[Messages] Set Selected Chat',
  props<{
    chat: IChat;
  }>()
);

export const sendMessage = createAction(
  '[Messages] Send Message',
  props<{
    message: IMessage;
  }>()
);

export const sendMessageSuccess = createAction(
  '[Messages] Send Message Success',
  props<{
    isSendMessageSuccess: boolean;
  }>()
);

export const sendMessageError = createAction(
  '[Messages] Send Message Error',
  props<{
    isSendMessageFailure: boolean;
  }>()
);

export const setRefreshAllMessages = createAction(
  '[Messages] Refresh all messages',
  props<{
    refreshAll: boolean;
  }>()
);
