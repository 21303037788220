export const initialAccountState: AccountState = {
  emailId: '',
  registerType: '',
  profileProgress: 0,
  isRegisterSuccess: false,
  registerSuccessMessage: '',
  loginError: '',
  isRegisterError: false,
  registerError: '',
  isLoginError: false,
  changePasswordError: '',
  isChangePasswordError: false,
  changePasswordSuccess: '',
  isChangePasswordSuccess: false,
  isAccountVerified: false,
  emailToVerify: '',
  token: '',
  refreshToken: '',
  refreshTokenFailed: false,
};

export interface AccountState {
  emailId: string;
  registerType: string;
  profileProgress: number;
  isRegisterSuccess: boolean;
  registerSuccessMessage: string;
  isRegisterError: boolean;
  registerError: string;
  isLoginError: boolean;
  loginError: string;
  changePasswordError: string;
  isChangePasswordError: boolean;
  changePasswordSuccess: string;
  isChangePasswordSuccess: boolean;
  isAccountVerified: boolean;
  emailToVerify: string;
  token: string;
  refreshToken: string;
  refreshTokenFailed: boolean;
}
