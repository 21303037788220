<nav-bar></nav-bar>
<main class="main">
  <div class="wrapper pt-3 settings-wrapper">
    <div class="common-bg">
      <div class="row">
        <div class="col-12">
          <div><h3>Settings</h3></div>
        </div>
      </div>
    </div>
    <div class="common-bg">
      <div class="row mt-3" *ngIf="registerType === 'USER'">
        <div class="col-lg-12 col-sm-12">
          <label class="mar-rt-10"
            >Make your account visible to recruiters</label
          >
          <mat-slide-toggle
            class="example-margin"
            [color]="'primary'"
            [ngModel]="allowProfileVisibilty"
            (ngModelChange)="onHideAccountChange($event)"
          >
          </mat-slide-toggle>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-12 col-sm-12">
          <label class="mar-rt-10">Delete my account permanently</label>
          <div class="form-check form-check-inline">
            <button
              class="btn btn-outline-danger"
              (click)="onDeleteAccountChange()"
            >
              Delete Account
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
