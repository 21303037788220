import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RecruiterService {
  constructor(private http: HttpClient) {}

  getRecruiterDetails(): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.getRecruiterDetails,
      {}
    );
  }

  getJobsCreated(request: { filter: {} }): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.getJobs,
      request
    );
  }

  updateRecruiterProfileDetails(payload: any): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.updateRecruiterProfile,
      payload
    );
  }

  likeUserProfile(payload: any): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.likeUserProfile,
      payload
    );
  }

  bookMarkUserProfile(payload: any): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.bookMarkUserProfile,
      payload
    );
  }
}
