import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'landing-page',
  templateUrl: './landing-page.component.html',
})
export class LandingPageComponent implements OnInit {
  toggleOptions: boolean = false;
  activeTab: number = 0;
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
      },
    },
    nav: true,
  };

  stepOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      768: {
        items: 3,
      },
    },
    nav: false,
  };
  seeneemaaData: any;

  jobsCreatedCount: number = 0;
  recruitersCount: number = 0;
  usersCount: number = 0;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.http.get('/assets/json/seeneemaa-data.json').subscribe(
      (data) => {
        this.seeneemaaData = data;
      },
      (error) => {
        console.error('Failed to load JSON data', error);
      }
    );
    this.http
      .get(environment.api.baseUrl + environment.api.getStats, {
        headers: { 'Content-Type': 'application/json' },
      })
      .subscribe(
        (data: any) => {
          this.jobsCreatedCount = data.jobsCreatedCount;
          this.recruitersCount = data.recruitersCount;
          this.usersCount = data.usersCount;
        },
        (error) => {
          console.error('Failed to load JSON data', error);
        }
      );
  }
}
