import { createAction, props } from '@ngrx/store';
import { RecruiterProfileInterface } from 'src/app/recruiter/interfaces/recruiter-profile.interface';

export const updateRecruiterDetails = createAction(
  '[Recruiter] Update Recruiter Details',
  props<{
    emailId: string;
    registerType: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
  }>()
);

export const getRecruiterDetails = createAction(
  '[Recruiter] Get Recruiter Details'
);

export const showRecruiterProfile = createAction(
  '[Recruiter] Show Recruiter Profile',
  props<{ showRecruiterProfile: boolean }>()
);

export const getRecruiterDetailsSuccess = createAction(
  '[Recruiter] Get Recruiter Details Success',
  props<{ payload: any }>()
);
export const getRecruiterDetailsError = createAction(
  '[Recruiter] Get Recruiter Details Error',
  props<{ error: boolean; message: string }>()
);

export const getJobsCreated = createAction(
  '[Recruiter] Get Jobs Created',
  props<{ filter: {} }>()
);

export const getJobsCreatedSuccess = createAction(
  '[Recruiter] Get Jobs Created Success',
  props<{ payload: any }>()
);

export const getJobsCreatedError = createAction(
  '[Recruiter] Get Jobs Created Error',
  props<{ error: boolean; message: string }>()
);

export const setReadOnlyProfile = createAction(
  '[Profile] Set Read Only Profile Success',
  props<{ payload: any }>()
);

export const resetReadOnlyProfile = createAction(
  '[Profile] Reset Read Only Profile'
);

export const updateRecruiterProfileDetails = createAction(
  '[Recruiter] Update Recruiter Profile Details',
  props<{ payload: RecruiterProfileInterface }>()
);

export const updateRecruiterProfileDetailsSuccess = createAction(
  '[Recruiter] Update Recruiter Profile Details Success',
  props<{ payload: any }>()
);

export const updateRecruiterProfileDetailsError = createAction(
  '[Recruiter] Update Recruiter Profile Details Error',
  props<{ error: boolean; message: string }>()
);

export const setProfilesFilter = createAction(
  '[Recruiter] Set Profiles Filter',
  props<{ filter: {} }>()
);

export const updateLikeForUserProfile = createAction(
  '[Recruiter] Update Like For User Profile',
  props<{ accountId: string; interactionFlag: boolean }>()
);

export const bookmarkUserProfile = createAction(
  '[Recruiter] Update Bookmark For User Profile',
  props<{ accountId: string; interactionFlag: boolean }>()
);
