export const initialState: JobsState = {
  jobs: [],
  createJobSuccess: false,
  createJobFailure: false,
  jobDetails: {},
  jobDetailsError: {},
  selectedJobId: '',
  jobsError: {},
  applyJobSuccess: {},
  applyJobFailure: {},
  editJobId: '',
  favoriteUpdated: false,
  createJobSuccessId: '',
  sortValue: 'byCreatedDate',
};

export interface JobsState {
  jobs: any[];
  jobsError: Object;
  createJobSuccess: boolean;
  createJobSuccessId: string;
  createJobFailure: boolean;
  selectedJobId: string;
  jobDetails: Object;
  jobDetailsError: Object;
  applyJobSuccess: Object;
  applyJobFailure: Object;
  editJobId: string;
  favoriteUpdated: boolean;
  sortValue: string;
}
