import { createAction, props } from '@ngrx/store';

export const createJob = createAction(
  '[Jobs] Create Job',
  props<{ job: any }>()
);

export const createJobSuccess = createAction(
  '[Jobs] Create Job Success',
  props<{ createJobSuccess: boolean; jobId: string }>()
);

export const createJobFailure = createAction(
  '[Jobs] Create Job Failure',
  props<{ createJobError: boolean }>()
);

export const setSelectedJobId = createAction(
  '[Jobs] Set Selected Job Id',
  props<{ id: string }>()
);

export const getJobDetails = createAction(
  '[Jobs] Get Job Details',
  props<{ jobId: any; accountId: string; registerType: string }>()
);

export const getJobDetailsSuccess = createAction(
  '[Jobs] Get Job Details Success',
  props<{ job: any }>()
);

export const getJobDetailsError = createAction(
  '[Jobs] Get Job Details Failure',
  props<{ error: any }>()
);

export const getJobs = createAction(
  '[Jobs] Get Jobs',
  props<{ filter: {}; applied?: boolean; bookmarked?: boolean }>()
);

export const getJobsSuccess = createAction(
  '[Jobs] Get Jobs Success',
  props<{ jobs: any }>()
);

export const getJobsError = createAction(
  '[Jobs] Get Jobs Failure',
  props<{ error: any }>()
);

export const applyJob = createAction(
  '[Jobs] Apply Job',
  props<{ jobId: any; accountId: string; registerType: string }>()
);

export const applyJobSuccess = createAction(
  '[Jobs] Apply Job Success',
  props<{ job: any }>()
);

export const applyJobFailure = createAction(
  '[Jobs] Apply Job Failure',
  props<{ error: any }>()
);

export const setEditJobId = createAction(
  '[Jobs] Set Edit Job',
  props<{ jobId: string }>()
);

export const setFavorite = createAction(
  '[Jobs] Set Favorite Job',
  props<{ jobId: string; accountId: string }>()
);

export const setFavoriteUpdated = createAction(
  '[Jobs] Set Favorite Updated',
  props<{ value: boolean }>()
);

export const setViewed = createAction(
  '[Jobs] Set Job Viewed',
  props<{ jobId: string; accountId: string }>()
);

export const acceptApplicant = createAction(
  '[Jobs] Accept Job Applicant',
  props<{
    jobId: any;
    accountId: string;
  }>()
);

export const acceptApplicantSuccess = createAction(
  '[Jobs] Accept Job Applicant Success',
  props<{ job: any }>()
);

export const acceptApplicantFailure = createAction(
  '[Jobs] Accept Job Applicant Failure',
  props<{ error: any }>()
);

export const rejectApplicant = createAction(
  '[Jobs] Reject Job Applicant',
  props<{
    jobId: any;
    accountId: string;
  }>()
);

export const rejectApplicantSuccess = createAction(
  '[Jobs] Reject Job Applicant Success',
  props<{ job: any }>()
);

export const rejectApplicantFailure = createAction(
  '[Jobs] Reject Job Applicant Failure',
  props<{ error: any }>()
);

export const sortJobs = createAction(
  '[Jobs] Sort Jobs',
  props<{ sortValue: string }>()
);

export const deleteJob = createAction(
  '[Jobs] Delete Job',
  props<{
    jobId: string;
    accountId: string;
    registerType: string;
  }>()
);
